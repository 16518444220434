import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ArticleCardDetails = ({ alt, image, slug, title, excerpt }) => {
  //console.log("Data: ", alt, image, slug, title, excerpt);
  return (
    <Link 
      to={`${slug}`}
      alt={title}
      aria-label={title}
    >
      <div className="relative overflow-hidden mb-5">
        <GatsbyImage image={image} alt={alt} className="" />
      </div>
      <div className="relative ">
        <div className="px-4">
          <h3 className="font-semibold text-[20px] mb-2 mt-2 text-black-grey-gradient">
            <span className="">{title}</span>
          </h3>

          <p className="font-normal text-[#152238] text-[14px] leading-[22.8px]">
            {excerpt.substring(0,100)} ...
          </p>

          <footer className="flex items-center justify-between leading-none mt-4 mb-6">
            {/* <div className="flex items-center no-underline text-[#152238]">
              <img
                alt="Placeholder"
                className="block rounded-full"
                src="https://picsum.photos/32/32/?random"
              />
              <p className="ml-2 text-[#152238] text-sm invisible xx:visible">
                Category Name
              </p>
            </div> */}
            <div className="flex mt-3">
              <StaticImage
                src="../images/profile-images/richard-profile-picture-v4.jpg"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="meet the team"
                className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
              />
              <div className="mb-2">
                <p className="font-semibold text-gray-700 text-sm capitalize">
                  {" "}
                  Richard Barber{" "}
                </p>
                <p className="text-quinary font-semibold text-xs">
                  {" "}
                  11 August 2023{" "}
                </p>
              </div>
            </div>
            {/* <div className="flex justify-between items-center no-underline ">
              <p className="mr-4 text-[#152238]">Learn more</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.125"
                height="13.358"
                viewBox="0 0 14.125 13.358"
              >
                <g transform="translate(-3 -3.293)">
                  <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M14.189,10.739H3V9.2H14.189L9.361,4.378l1.085-1.085,6.679,6.679-6.679,6.679L9.361,15.566Z"
                    fill="#1d1d1d"
                    fillRule="evenodd"
                  ></path>
                </g>
              </svg>
            </div> */}
          </footer>
        </div>
      </div>
    </Link>
  );
};

export default ArticleCardDetails;
