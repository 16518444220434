import React from "react";
import styles from "../styles/style.js";
import { GatsbyImage } from "gatsby-plugin-image";
import Headings from "./Headings.js";
import Button from "./Button.js";
import { Link } from "gatsby";

const FeaturedProject = ({ projectDetails, projectImages, projectHeader }) => {
  //console.log("Project Details: ", projectDetails);
  //console.log("Project Images: ", projectImages);
  //console.log("Project Header: ", projectHeader);

  return (
    <section
      id={projectHeader.id}
      className={`bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <section className={`bg-[#ffffff] mt-4 ${styles.flexCenter}`}>
          <div>
            <Headings
              titleTop={projectHeader.titleTop}
              titleBottom={projectHeader.titleBottom}
              description={projectHeader.content}
            />

            <div className="grid gap-4 row-gap-5 mb-4 lg:grid-cols-4 sm:row-gap-2 sm:grid-cols-2 mt-4">
              <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                <GatsbyImage
                  image={projectImages[0].childImageSharp.gatsbyImageData}
                  alt={projectImages[0].name}
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                />
                <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-4 text-lg font-bold text-gradient-yellow">
                    {projectDetails[0].title}
                  </p>
                  <p className="text-sm tracking-wide text-gray-300">
                    {projectDetails[0].content}
                  </p>
                </div>
              </div>

              <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                <GatsbyImage
                  image={projectImages[1].childImageSharp.gatsbyImageData}
                  alt={projectImages[1].name}
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                />
                <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-4 text-lg font-bold text-gradient-yellow">
                    {projectDetails[1].title}
                  </p>
                  <p className="text-sm tracking-wide text-gray-300">
                    {projectDetails[1].content}
                  </p>
                </div>
              </div>

              <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                <GatsbyImage
                  image={projectImages[2].childImageSharp.gatsbyImageData}
                  alt={projectImages[2].name}
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                />
                <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-4 text-lg font-bold text-gradient-yellow">
                    {projectDetails[2].title}
                  </p>
                  <p className="text-sm tracking-wide text-gray-300">
                    {projectDetails[2].content}
                  </p>
                </div>
              </div>

              <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                <GatsbyImage
                  image={projectImages[3].childImageSharp.gatsbyImageData}
                  alt={projectImages[3].name}
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                />
                <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-4 text-lg font-bold text-gradient-yellow">
                    {projectDetails[3].title}
                  </p>
                  <p className="text-sm tracking-wide text-gray-300">
                    {projectDetails[3].content}
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <Link
                to={projectHeader.link}
                aria-label="Read more button"
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                <Button label="Read more" styles={`mt-2`} />
              </Link>
            </div>
          </div>
        </section>

        {/* stop */}
      </div>
    </section>
  );
};

export default FeaturedProject;
