import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import styles from "../styles/style";

const HeroV7 = () => (
  <div className={`z-200 bg-[#1d1d1d]`}>
    <section className="showcase">
      {/* Background image */}
      <StaticImage
        src="../images/hero-dhl.jpg"
        placeholder="blurred"
        formats={["auto", "webp", "avif"]}
        alt="roof with 4 Velux windows"
        //transformOptions={{ fit: "cover", cropFocus: "attention" }}
        aspectRatio={1.5}
        className="w-[100%] h-[100%] "
      />
      <div className="flex h-full items-center justify-center overlay">
        <div className="px-6 text-center text-white md:px-12">
          <div className="flex items-center justify-center w-full ">
            <div
              className="ss:flex hidden text-[#FFFFFF] text-2xl"
              style={{ maxWidth: "420px" }}
            >
              <StaticImage
                src="../images/logos/intact-electrical-full-info-v2.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="roof with 4 Velux windows"
                //aspectRatio={1.99}
                loading="lazy"
              />
            </div>
            <div
              className="ss:hidden flex text-[#FFFFFF] text-2xl"
              style={{ maxWidth: "220px" }}
            >
              <StaticImage
                src="../images/logos/intact-electrical-with-logo-and-strp-mobile.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="roof with 4 Velux windows"
                //aspectRatio={1.99}
                loading="lazy"
              />
            </div>
          </div>

          <div
            className={`${styles.heroExcerpt}`}
            //"text-[#FFFFFF] mt-6"
            style={{ maxWidth: "550px" }}
          >
            <p>
              We're committed to providing a professional service first time
              every time. Considering your needs every step of the way.
            </p>
          </div>

          <div
            className="flex items-center justify-center w-full mb-6 mt-4"
            style={{ maxWidth: "500px" }}
          >
            <a
              href="/services"
              aria-label="services button"
              alt="services button"
              //className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              <button
                type="button"
                className="mt-4 mr-6 rounded border-2 border-neutral-50 px-[20px] pt-[6px] pb-[6px] text-sm font-medium leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-100 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Services
              </button>
            </a>
            <a
              href="/projects"
              aria-label="projects button"
              alt="projects button"
              //className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              <button
                type="button"
                className="mt-4 rounded border-2 border-neutral-50 px-[20px] pt-[6px] pb-[6px] text-sm font-medium leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-100 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Projects
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default HeroV7;
