import React from "react";
import ArticleCardDetails from "../ArticleCardDetails";

const BlogItem = ({ alt, image, slug, title, excerpt }) => {
  return (
    <>
      <div className="rounded-lg overflow-hidden shadow-md hover:opacity-50 mt-4">
        <ArticleCardDetails
          alt={alt}
          image={image}
          slug={slug}
          title={title}
          excerpt={excerpt}
        />
      </div>
    </>
  );
};

export default BlogItem;
