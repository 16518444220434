import React from "react";
import { features, trustedServices } from "../constants";
import styles, { layout } from "../styles/style";
import Button from "./Button";
import Title from "./Title";
import { Link } from "gatsby";

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row p-4 rounded-[20px] bg-black-gradient ${
      index !== features.length - 1 ? "mb-4" : "mb-0"
    } feature-card`}
  >
    <div
      className={`w-[64px] h-[64px] flex rounded-full ${styles.flexCenter} bg-quinary`}
    >
      <img
        src={icon}
        alt="star"
        className="w-[50%] h-[50%] object-contain fill-[#243c5a] "
        style={{ fill: "#FFFFFF" }}
      />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-semibold text-gradient-yellow text-[18px] leading-[33.4px] mb-2">
        {title}
      </h4>
      <p className="font-normal text-white text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const TrustedServices = () => {
  //console.log("trustedServices: ", trustedServices);

  return (
    <div
      id="trusted-services"
      className={`bg-[#ffffff]  ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <div className={`bg-primary`}>
          <div>
            <section id="about-us" className={layout.section}>
              <div className={layout.sectionInfo}>
                <Title
                  titleTop={trustedServices.titleTop}
                  titleBottom={trustedServices.titleBottom}
                />
                <p className={`${styles.paragraph2} mt-2`}>
                  {trustedServices.content1}
                </p>

                <p className={`${styles.paragraph2} mt-2`}>
                  {trustedServices.content2}
                </p>
                <Link to="/services" alt="contact us">
                  <Button label={trustedServices.buttonLabel} styles={`mt-4`} />
                </Link>
              </div>

              <div className={`${layout.sectionImg} flex-col `}>
                {features.map((feature, index) => (
                  <FeatureCard key={feature.id} {...feature} index={index} />
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedServices;
